import * as React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import {Helmet} from "react-helmet";
import styled from "styled-components";

const Layout = ({ children }) => {
	const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

	return (
		<LayoutContainer>
			<Helmet>
				<meta charSet="utf-8" />
				<title>{data.site.siteMetadata.title}</title>
				<meta name="robots" content="noindex" />
				<meta name="googlebot" content="noindex" />
			</Helmet>
			<HeaderDiv><header  className="site-title"><Link to={"/"}>{data.site.siteMetadata.title}</Link></header></HeaderDiv>
			{
				/*

				<nav>
				<ul className={navLinks}>
					<li className={navLinkItem}>
						<Link to="/search" className={navLinkText}>
							Recherche de recettes
						</Link>
					</li>
				</ul>
			</nav>

				*/
			}
			<main>
				{children}
			</main>
		</LayoutContainer>
	)
}

const LayoutContainer = styled.div`
  width: calc(100% - 30px);
  display: block;
  font-family: sans-serif;
  margin : 15px;
`

const HeaderDiv = styled.div`
  .site-title {
    font-size: 3rem;
    color: gray;
    font-weight: 700;
    padding: 2rem 0;
    margin: 1rem 0;
    border-radius: 25px;
    text-align: center;
    a {
      text-decoration : none;
      color : #e80046;
    }
    
    :hover {
    	background: #e80046;
        a {
          color : white;
        }
  	}
  }
`

export default Layout